@use "sass:map";
@import '../../../../styles/constants/breakpoints';
@import '../../../../styles/constants/colors';

.homePageSectionOne {
  background-color: map.get($background-and-border-colors, background-light-blue);
  background-image: linear-gradient(to bottom, map.get($primary-colors, primary) 0%, map.get($hubColors, blue-color-dark) 100%);

  .bannerText {
    padding: 0 29px;
    color: $color-white;
    text-align: center;

    h1, p {
      max-width: 100%;
    }

    a {
      color: white;
      text-decoration: underline;

      @media #{$mdMedia} {
        margin-bottom: 0;
      }

    }

    @media #{$smMedia} {
      padding: 0 6rem;
    }

    @media #{$mdMedia} {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 0 30px;
      text-align: left;
    }
  }


  h1 {
    margin: 4.5rem 0 2rem;
    font-size: 2em;

    @media #{$smMedia} {
      margin-top: 6.25rem;
      font-size: 3em;
    }

    @media #{$mdMedia} {
      margin-top: 1.5rem;
    }
  }

  p {
    margin: 0 0 1.5rem;
    font-size: 21px;
    line-height: 1.4;
    opacity: 0.8;
  }
}

.imageContainer {
  position: relative;
  height: 350px;
  font-size: 0;
  text-align: center;

  img:nth-of-type(1) {
    position: relative;
    z-index: 2;
    height: 100%;
  }

  img:nth-of-type(2) {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    max-width: 100%;
    height: 100%;
    opacity: .5;
    transform: translateX(-50%);
  }
}

@media #{$mdMedia} {
  .homePageSectionOne .imageContainer {
    height: auto;

    img:nth-of-type(1) {
      width: 100%;
      height: auto;
    }

    img:nth-of-type(2) {
      top: 50%;
      left: 0;
      height: auto;
      transform: translateY(-50%);
    }
  }
}
